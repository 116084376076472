<template>
  <div class="container">
    <div class="flex w-full items-center justify-center">
      <svg
        width="100"
        height="100"
        viewBox="0 0 100 100"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        style="width: 400px; height: 400px;"
      >
        <rect
          class="stroke1 fill1"
          x="21"
          y="7"
          width="53"
          height="86"
          fill="theme(colors.red.500)"
          stroke="#000"
          stroke-width="1.0px"
          style="animation-duration: 4s;"
        ></rect>
        <rect
          class="stroke1 fill1"
          width="12"
          height="86"
          transform="matrix(-1 0 0 1 21 7)"
          fill="rgba(128,199,146,1)"
          stroke="#000"
          stroke-width="1.0px"
          style="animation-duration: 4s;"
        ></rect>
        <rect
          class="stroke1 fill1"
          x="31"
          y="22"
          width="33"
          height="18"
          fill="rgba(128,199,146,1)"
          stroke="#000"
          stroke-width="1.0px"
          style="animation-duration: 4s;"
        ></rect>
        <path
          class="stroke1"
          d="M36 28H59"
          stroke="#000"
          stroke-width="1.0px"
          style="animation-duration: 4s;"
        ></path>
        <path
          class="stroke1"
          d="M36 34H59"
          stroke="#000"
          stroke-width="1.0px"
          style="animation-duration: 4s;"
        ></path>
        <circle
          class="stroke2 fill2"
          cx="74"
          cy="75"
          r="18"
          fill="rgba(184,233,134,1)"
          stroke="rgba(0,0,0,1)"
          stroke-width="1.0px"
          style="animation-duration: 4s;"
        ></circle>
        <path
          class="line-book1 stroke2 fill2"
          d="M65 76.8L70.1429 82L83 69"
          stroke="rgba(0,0,0,1)"
          stroke-width="1.0px"
          style="animation-duration: 4s;"
        ></path>
      </svg>
      <div class="">
        <h1 class="text-6xl">Complimenti!</h1>
        <h2 class="text-2xl">Il tuo ordine è stato confermato!</h2>
      </div>
    </div>
    <div class="mt-12 flex flex-col justify-center items-center">
      <router-link class="btn btn-cta mt-12" to="/">Home</router-link>
    </div>
  </div>
</template>

<script>
import { mapMutations } from "vuex";
export default {
  mounted() {
    console.log("SVUOTO IL CARRELLO");

    //this.EMPTY_CART();
  },
  methods: {
    ...mapMutations("cart", ["EMPTY_CART"])
  }
};
</script>

<style lang="postcss" scoped>
.fill1 {
  @apply fill-current;
  color: theme("colors.white");
}
.fill2 {
  @apply fill-current;
  color: theme("colors.secondary.200");
}
.stroke1,
.stroke2 {
  @apply fill-current;
  stroke: theme("colors.secondary.500");
}
.line-book1 {
  animation: line-book1-tick 2s infinite;
  stroke-dasharray: 110;
  stroke-dashoffset: 110;
}
@keyframes line-book1-tick {
  20% {
    stroke-dashoffset: 110;
  }
  60% {
    stroke-dashoffset: 0;
  }
  100% {
    stroke-dashoffset: 0;
  }
}
@media (prefers-reduced-motion: reduce) {
  .line-book1 {
    animation: none;
  }
}
</style>
